* {
  padding: 0px;
  margin: 0px;
}

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.menuebar {
  background-color: black;
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 30px;
}

/* Deop down menue **/
.menu-container {
  position: relative;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  z-index: 10;
  top: 60px;
  left: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.menu-trigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

.menu-trigger img {
  border-radius: 90px;
}

/* END Drop down mwnu */

.page-header {
  background-color: lightgrey;
  margin-top: 0px;
  margin-bottom: 0px;
  color: maroon;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
/*
.page-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  background-color: lightgrey;
  color: black;
  font-size: 1em;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: solid;
}
*/
.content-container {
  margin-top: 100px;
  margin-bottom: 20px;
}

.frame-section {
  text-align: center;
  width: min(560px, 90%);
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border: solid;
}

.page-header h1 {
  font-size: 1.4em;
}

.page-header h2 {
  font-size: 1.2em;
}

.button-container {
  margin-top: 1rem;
}

/* Buttons */
/* OK like buttons */
.button-ok {
  position: relative;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  width: 50px;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button-ok:hover {
  background-color: #83c985;
}

.button-ok:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.button-ok:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

/* Abort like buttons */
.button-abort {
  position: relative;
  background-color: #f4511e;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  width: 50px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button-abort:hover {
  background-color: #f77a55;
}

.button-abort:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.button-abort:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

/* Button delete */
.button-delete {
  position: relative;
  background-color: #f49e1e;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  width: 50px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button-delete:hover {
  background-color: #fcb955;
}

.button-delete:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.button-delete:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.noBorder {
  border: none;
}
