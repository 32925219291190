/*
h1 {
  color: maroon;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 1px;
  font-size: 20px;
}

h2 {
  color: maroon;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 15px;
}

h3 {
  color: maroon;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
}
*/
a {
  color: black;
  text-decoration: none; /* no underline */
}

.calendar-container {
  width: 100%;
}
.day-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1em;
  border: 1px solid #000;
}
.grid-day {
  border: 1px solid #000;
}

.main-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100vw;
  /*    width: 100%;
   overflow: hidden; */
  grid-template-areas:
    "menuebar"
    "header"
    "time-sheet"
    "footer";
}

.menuebar-container {
  grid-area: menuebar;
  text-align: left;
  background-color: black;
  color: white;
  padding-left: 30px;
}

.menuebar-item {
  background-color: black;
  color: white;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.menuebar-dropdown-content {
  display: block;
  position: absolute;
  left: 0;
  background-color: #f9f9f9;
  min-width: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 2;
}

.menuebar-item:hover .menuebar-dropdown-content,
.menuebar-item:focus {
  cursor: pointer;
  color: black;
  display: block;
}

.menuebar-dropdown-content a:hover {
  font-weight: bold;
}

.loginArrowUp {
  display: none;
}

.menuebar-item:hover .loginArrowUp {
  display: inline;
}

.menuebar-item:hover .loginArrowDown {
  display: none;
}

.page-header {
  grid-area: header;
  text-align: center;
  background-color: lightgrey;
  margin-top: 0px;
  /*  border: 1px solid #000; */
}

.page-footer {
  grid-area: footer;
  background-color: lightgrey;
  font-size: 18px;
  text-align: center;
  align-self: start;
  padding: 10px;
}

.footer {
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  grid-template-areas: "footer-left footer-center footer-right";
}

.footer-left {
  text-align: left;
}

.footer-center {
  text-align: center;
}

.footer-right {
  text-align: right;
}

.time-container-scroller {
  overflow-x: scroll;
  width: 100vw;
  /* height: 100vh;*/
}

.time-container {
  display: grid;
  grid-area: time-sheet;
  grid-template-columns: 50px repeat(7, 1fr);
  background-color: white;
  /*  width: 1128px; */
  min-width: 987px;
  /* overflow-x: scroll; */
  /*  border: 1px solid #000; */
}

.date-header {
  font-weight: bold;
  text-align: center;
  border: 1px solid #000;
}

.time-item {
  font-weight: bold;
  text-align: center;
  font-size: 0.8rem;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  max-width: 100px;
  background-color: white;
  border: 1px solid #000;
  left: 0;
  z-index: 1;
}

.time-slot-container {
  display: grid;
  grid-template-columns: 1fr 25px 1fr 30px 30px 30px;
  grid-template-rows: auto 1fr;
  border: 1px solid #000;
  grid-column-gap: 5px;
  grid-template-areas:
    "button lesson . special alone activity"
    "button lesson comment comment comment comment";
}

.time-slot {
  grid-area: button;
  text-align: center;
  padding: 2px;
}

.activity-type {
  grid-area: activity;
  grid-row-end: 2;
  position: relative;
  text-align: center;
  margin-top: 2px;
  margin-right: 2px;
  color: white;
  font-weight: bold;
  border: 1px solid black;
}

.activity-type:hover,
.activity-type:focus {
  cursor: pointer;
}

.activity-lesson {
  grid-area: lesson;
  display: block;
  color: #000;
  writing-mode: vertical-lr;
  text-orientation: sideways;
  font-weight: normal;
  font-size: 0.9em;
  padding: 3px;
  background-color: lightgray;
  border: 1px solid #000;
}

.activity-lesson:hover,
.activity-lesson:focus {
  cursor: pointer;
}

.activity-special {
  grid-area: special;
  display: block;
  text-align: center;
  align-self: top;
  margin-top: 2px;
  margin-right: 1px;
  /*  margin-left: 5px; */
  color: white;
  font-weight: bold;
  background-color: rgb(86, 86, 86);
}

.activity-special:hover,
.activity-special:focus {
  cursor: pointer;
}

.activity-alone {
  grid-area: alone;
  display: block;
  text-align: center;
  align-self: top;
  margin-top: 2px;
  margin-right: 1px;
  /*  margin-left: 5px; */
  color: white;
  font-weight: bold;
  background-color: rgb(86, 86, 86);
}

.activity-alone:hover,
.activity-alone:focus {
  cursor: pointer;
}

.activity-comment {
  grid-area: comment;
  text-align: center;
  align-self: center;
  color: black;
  font-size: 0.8em;
  font-weight: normal;
  background-color: lightgray;
  border: 1px solid #000;
}

.activity-comment:hover,
.activity-comment:focus {
  cursor: pointer;
}

.button-book {
  position: relative;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  width: 50px;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button-book:hover {
  background-color: #83c985;
}

.button-book:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.button-book:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.button-booked {
  position: relative;
  background-color: #f4511e;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  width: 50px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  display: block;
}

.button-booked:hover {
  background-color: #f77a55;
}

.button-booked:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.button-booked:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.button-week {
  position: relative;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  width: 50px;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button-week-inactive {
  position: relative;
  background-color: lightgray;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  width: 50px;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button-week:hover {
  background-color: #83c985;
}

.button-week:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.button-week:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.button-change-update {
  position: relative;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  width: 50px;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button-change-update:hover {
  background-color: #83c985;
}

.button-change-update:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.button-change-update:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.button-change-delete {
  position: relative;
  background-color: #f49e1e;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  width: 50px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button-change-delete:hover {
  background-color: #fcb955;
}

.button-change-delete:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.button-change-delete:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.button-change-abort {
  position: relative;
  background-color: #f4511e;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  padding: 4px;
  width: 50px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button-change-abort:hover {
  background-color: #f77a55;
}

.button-change-abort:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.button-change-abort:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

input-booked[type="submit"] {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}

/* The Book Modal (background) */
.book-modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Book Modal Content */
.book-modal-content {
  display: grid;
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 450px;
  max-width: 100vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "header header"
    "name activity"
    "buttons buttons"
    "footer footer";
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.bookClose {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.bookClose:hover,
.bookClose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.book-modal-header {
  grid-area: header;
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

h2.bookHeader {
  color: white;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
}

h3.bookBody {
  color: black;
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 1px;
  text-align: left;
}

h3.bookFooter {
  color: white;
  font-weight: normal;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
}

p.bookHeader {
  color: white;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
}

p.bookNote {
  font-size: 0.9em;
}

.book-modal-name {
  grid-area: name;
  padding: 2px 16px;
}

.book-modal-activity {
  grid-area: activity;
  padding: 2px 16px;
}

.book-modal-buttons {
  grid-area: buttons;
  padding: 2px 16px;
  margin-bottom: 5px;
  text-align: center;
}

.book-modal-footer {
  grid-area: footer;
  padding: 2px 16px;
  background-color: #5cb85c;
  font-size: 0.9em;
  color: white;
}

/* The Change booking Book Modal (background) */
.change-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Change booking Modal Content */
.change-modal-content {
  display: grid;
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 450px;
  max-width: 100vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "header header"
    "name activity"
    "buttons buttons"
    "footer footer";
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.changeClose {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.changeClose:hover,
.changeClose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.change-modal-header {
  grid-area: header;
  padding: 2px 16px;
  background-color: #f4511e;
  color: white;
}

h2.changeHeader {
  color: white;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
}

h3.changeBody {
  color: black;
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 1px;
  text-align: left;
}

h3.changeFooter {
  color: white;
  font-weight: normal;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
}

p.changeHeader {
  color: white;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
}

p.changeNote {
  font-size: 0.9em;
}

.change-modal-name {
  grid-area: name;
  padding: 2px 16px;
}

.change-modal-activity {
  grid-area: activity;
  padding: 2px 16px;
}

.change-modal-buttons {
  grid-area: buttons;
  padding: 2px 16px;
  margin-bottom: 5px;
  text-align: center;
}

.change-modal-footer {
  grid-area: footer;
  padding: 2px 16px;
  background-color: #f4511e;
  font-size: 0.9em;
  color: white;
}

/* The Login Modal (background) */
.login-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Login Modal Content */
.login-modal-content {
  display: grid;
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 450px;
  max-width: 100vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "body"
    "buttons"
    "footer";
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.loginClose {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.loginClose:hover,
.loginClose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.login-modal-header {
  grid-area: header;
  padding: 2px 16px;
  background-color: #f49e1e;
  color: white;
}

h2.loginHeader {
  color: white;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
}

.login-modal-body {
  grid-area: body;
  padding: 2px 16px;
  text-align: center;
}

.login-modal-buttons {
  grid-area: buttons;
  padding: 2px 16px;
  margin-bottom: 5px;
  text-align: center;
}

.login-modal-footer {
  grid-area: footer;
  padding: 2px 16px;
  background-color: #f49e1e;
  font-size: 0.9em;
  color: white;
}

.frame-section {
  grid-area: time-sheet;
  text-align: center;
  width: 560px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: solid;
}
